<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0;margin-left: 20px;font-size: 18px" type="text" @click="addClick">
          记一笔
        </el-button>
        <el-button style="float: right; padding: 3px 0;font-size: 18px" type="text" @click="addKeepDeclare">申报
        </el-button>
        <el-form :inline="true" :model="keepAccountsQuery" class="demo-form-inline" @keyup.native.enter="onSubmit">
          <el-form-item label="时间">
            <el-date-picker
                v-model="keepAccountsQuery.keepTimeDay"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="keepAccountsQuery.paymentType" placeholder="请选择">
              <el-option label="请选择" value=""></el-option>
              <el-option label="收入" value="0"></el-option>
              <el-option label="支出" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          :data="keepAccountsList"
          style="width: 100%;"
          v-loading="loading"
          :header-cell-style="{'text-align':'center'}"
          @selection-change="selectChange">
        <el-table-column
            type="selection"
            width="55"
            :selectable="checkSelectTale"
            align="center">

        </el-table-column>
        <el-table-column
            fixed
            prop="paymentType"
            label="收支方式"
            width="80"
            align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.paymentType === 0">收入</span>
            <span v-if="scope.row.paymentType === 1">支出</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="feeTypeName"
            label="费用类型"
            align="center">
        </el-table-column>
        <el-table-column
            prop="money"
            label="金额"
            align="center">
        </el-table-column>
        <el-table-column
            prop="keepTimeDay"
            label="日期"
            align="center">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-if="scope.row.status === 0">未提交</el-tag>
            <el-tag type="success" size="small" v-if="scope.row.status === 1">已提交</el-tag>
            <el-tag size="small" v-if="scope.row.status === 2">已纳税</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="contractName"
            label="合同"
            align="center">
          <template slot-scope="scope">
            <el-popover
                placement="right"
                trigger="click">
              <ul>
                <li v-for="item in contract" :key="item.id">
                  <el-link type="primary" slot="reference" @click="checkContractById(item.fileUrl)">{{
                      item.contractName
                    }}
                  </el-link>
                </li>
              </ul>
              <el-link type="primary" slot="reference" @click="getContractById(scope.row.contractId)">
                {{ scope.row.contractName }}
              </el-link>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" @click="checkClick(scope.row.id)">查看</el-button>
              <el-button type="primary" v-if="scope.row.status===0" @click="updateClick(scope.row.id)">编辑</el-button>
              <el-button type="danger" v-if="scope.row.status===0" @click="delClick(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top: 10px"
                     @size-change="sizeChange"
                     @current-change="currentChange"
                     :current-page.sync="keepAccountsQuery.page"
                     :page-size="keepAccountsQuery.size"
                     :total="keepAccountsQuery.total"
                     background
                     layout="prev, pager, next">
      </el-pagination>
    </el-card>
    <el-drawer
        :destroy-on-close="true"
        :visible.sync="keepAccountsShow"
        :with-header="false"
        size="50%">
      <cost-p :keepAccountsId="keepAccountsId"></cost-p>
    </el-drawer>
    <el-drawer
        :destroy-on-close="true"
        :visible.sync="keepAccountsAddShow"
        :with-header="false"
        direction="ltr"
        size="60%" :before-close="keepAccountsClose">
      <add-cost @addKeepAccountsYes="addKeepAccountsClose"/>
    </el-drawer>
    <el-drawer
        :destroy-on-close="true"
        :visible.sync="keepAccountsUpdateShow"
        :with-header="false"
        direction="ltr"
        size="60%" :before-close="keepAccountsClose">
      <update-cost @updateKeepAccountsYes="updateKeepAccountsClose" :keepAccountsId="keepAccountsId"/>
    </el-drawer>
  </div>
</template>

<script>
import CostP from "@/views/pc/cost/cost";
import AddCost from "@/views/pc/cost/add";
import {delKeepAccounts, getContractById, getKeepAccountsPageList} from "@/api/cost/cost";
import UpdateCost from "@/views/pc/cost/update";
import VantMessage from "@/utils/vantMessageUtils";
import MessageUtils from "@/utils/elementMessageUtils";
import {addKeepDeclare} from "@/api/declare/declare";
import {getUserById} from "@/api/user/user";

export default {
  name: "costList",
  data() {
    return {
      loading: false,
      keepAccountsShow: false,
      keepAccountsAddShow: false,
      keepAccountsUpdateShow: false,
      keepAccountsList: [],
      keepAccountsId: Number,
      keepAccountsQuery: {
        keepTimeDay: '',
        paymentType: '',
        page: 1, //第几页
        size: 9, //每页查询几条数据
        total: 0, // 总条数
      },
      updateKeepAccounts: [],
      ids: [],
      contract: [],
    }
  },
  components: {
    CostP,
    AddCost,
    UpdateCost
  },
  methods: {
    checkSelectTale(row) {

      if (row.status === 0) {
        if (row.contractId === null) {
          return row.asgnmentMainMake = 0;
        }
        return row.asgnmentMainMake = 1;
      }
    },
    selectChange(selection) {
      this.ids = [];
      selection.forEach(item => {
        this.ids.push(item.id)
      })
    },
    async addKeepDeclare() {
      let user = await getUserById(null);
      if (user.realStatus === 0) {
        this.$dialog.alert({
          title: '提示',
          message: '您的账号未实名认证，请前往 “创扶企规宝” 公众号进行实名认证',
          theme: 'round-button',
        }).then(() => {
          // on close
        });
      } else {
        if (this.ids.length > 0) {
          this.loading = true;
          let res = await addKeepDeclare({keepAccountsId: this.ids});
          this.loading = false;
          if (res) {
            MessageUtils.successMsg("提交成功！");
            await this.getKeepAccountsPageList();
          }
        } else {
          MessageUtils.errorMsg("请选择需要申报的费用")
        }
      }


    },
    onSubmit() {
      this.keepAccountsQuery.page = 1;
      this.getKeepAccountsPageList();
    },
    sizeChange(val) {
      this.keepAccountsQuery.page = val;
      this.getKeepAccountsPageList();
    },
    currentChange(val) {
      this.keepAccountsQuery.page = val;
      this.getKeepAccountsPageList();
    },
    async getKeepAccountsPageList() {
      this.loading = true;
      let res = await getKeepAccountsPageList(this.keepAccountsQuery);
      this.loading = false;
      if (res) {
        this.keepAccountsList = res.keepAccountsList
        this.keepAccountsQuery.total = res.count
      }
    },
    checkClick(id) {
      this.keepAccountsId = id;
      this.keepAccountsShow = true;
    },
    updateClick(id) {
      this.keepAccountsUpdateShow = true;
      this.keepAccountsId = id;
    },
    delClick(keepAccountsId) {
      MessageUtils.confirmMsg("你确定要删除该记录吗？").then(async resolve => {
        if (resolve) {
          let res = await delKeepAccounts({keepAccountsId})
          if (res) {
            MessageUtils.successMsg("删除成功")
            await this.getKeepAccountsPageList();
          } else {
            MessageUtils.errorMsg("删除失败，请稍后再试")
          }
        }
      })
    },
    addClick() {
      this.keepAccountsAddShow = true;
    },
    addKeepAccountsClose() {
      this.keepAccountsAddShow = false;
      this.getKeepAccountsPageList();
    },
    keepAccountsClose(done) {
      this.$confirm('确定要关闭吗？')
          // eslint-disable-next-line no-unused-vars
          .then(_ => {
            done();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {
          });
    },
    updateKeepAccountsClose() {
      this.keepAccountsUpdateShow = false;
      this.getKeepAccountsPageList();
    },
    async getContractById(contractId) {
      let res = await getContractById({contractId});
      if (res.length > 0) {
        this.contract = res
      }
    },
    checkContractById(url) {
      window.open(url)
    }
  },
  mounted() {
    this.getKeepAccountsPageList();
  }

}
</script>

<style scoped>

</style>
