<template>
  <div style="padding: 10px">
    <el-divider content-position="left">添加记账</el-divider>
    <el-form ref="keepAccounts" :model="keepAccounts" label-width="100px" label-position="right" :rules="rules">
      <el-form-item label="费用类型" prop="paymentType">
        <el-select v-model="keepAccounts.paymentType" placeholder="请选择">
          <el-option label="请选择" value=""></el-option>
          <el-option label="收入" value="0"></el-option>
          <el-option label="支出" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期" prop="keepTimeDay">
        <el-date-picker
            v-model="keepAccounts.keepTimeDay"
            align="right"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="金额" prop="money">
        <el-input v-model="keepAccounts.money"></el-input>
      </el-form-item>
      <el-form-item label="费用类型" prop="feeTypeId">
        <el-select v-model="keepAccounts.feeTypeId" placeholder="请选择" @focus="getKeepFeeTypeList"
                   :loading="paymentTypeLoading">
          <el-option
              v-for="item in keepFeeTypeList"
              :key="item.id"
              :label="item.feeTypeName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务合同" >
        <el-select v-model="keepAccounts.contractId" placeholder="请选择" @focus="getKeepContractList"
                   :loading="contractLoading">
          <el-option
              v-for="item in keepContractList"
              :key="item.id"
              :label="item.contractName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="费用单据">
        <van-uploader v-model="feeArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                      :before-delete="delUploadFile" name="feeArrays" :before-read="beforeRead"/>
      </el-form-item>
      <el-form-item label="发票">
        <van-uploader v-model="invoiceArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                      :before-delete="delUploadFile" name="invoiceArrays" :before-read="beforeRead"/>
      </el-form-item>
      <el-form-item label="业务佐证">
        <van-uploader v-model="epiArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                      :before-delete="delUploadFile" name="epiArrays" :before-read="beforeRead"/>
      </el-form-item>
      <el-form-item label="佐证(文件)">
        <van-uploader v-model="epiFileArrays" :after-read="uploadFile" upload-icon="description" name="epiFileArrays"
                      accept=".doc,.docx,.pdf,.xlsx,.xls"
                      :before-read="beforeRead" :before-delete="delUploadFile"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="loading">{{ loading ? '提交中 ...' : '提交' }}</el-button>
        <el-button @click="empty">清空</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getDateNumber} from "@/utils/dateUtils";
import {getUUID} from "@/utils/UUIDUtils";
import {getKeepContractList, getKeepFeeTypeList, getTempSecretKey} from "@/api/comm/comm";
import cos from "@/utils/objectQCos"
import MessageUtils from "@/utils/elementMessageUtils";
import {ImagePreview} from 'vant';
import {addKeepAccounts} from "@/api/cost/cost";
import VantMessage from "@/utils/vantMessageUtils";

export default {
  name: "addCostP",
  data() {
    return {
      pattern: /^([0-9]|[1-9]\d+)(\.\d{2})?$/,
      disabled: false,
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      paymentTypeLoading: false,
      contractLoading: false,
      keepAccounts: {
        paymentType: '',
        feeTypeId: '',
        money: '',
        contractId: '',
        keepTimeDay: '',
        feeArray: [],
        invoiceArray: [],
        epiArray: [],
        epiFileArray: []
      },
      feeArrays: [],
      invoiceArrays: [],
      epiArrays: [],
      epiFileArrays: [],
      keepFeeTypeList: [],
      keepContractList: [],
      rules: {
        paymentType: [
          {required: true, message: '请选择收支类型', trigger: ['blur', 'change']}
        ],
        keepTimeDay: [
          {required: true, message: '请选择日期', trigger: ['blur', 'change']}
        ],
        money: [
          {required: true, message: '请输入金额', trigger: 'blur'},
          {pattern: /^([1-9]|[0-9]\d+)(\.\d{2})?$/, message: '只能输入正整数(小数点2位)'}
        ],
        feeTypeId: [
          {required: true, message: '请选择费用类型', trigger: ['blur', 'change']}
        ],
      }
    }
  },
  methods: {
    // 返回布尔值
    beforeRead(file, detail) {
      let fileName = file.name;
      let type = fileName.substring(fileName.lastIndexOf("."));//.tx
      if (detail.name === 'epiFileArrays') {
        if (type.toLowerCase() !== '.doc' && type.toLowerCase() !== '.docx' && type.toLowerCase() !== '.pdf'
            && type.toLowerCase() !== '.xlsx' && type.toLowerCase() !== '.xls') {
          MessageUtils.errorMsg("请上传/doc/docx/pdf/xlsx/xls/类型文件")
          return false;
        } else if (file.size > 51200 * 1024) {
          MessageUtils.errorMsg("请上传小于50M的文件")
          return false;
        }
      } else {
        if (type.toLowerCase() !== '.png' && type.toLowerCase() !== '.jpg' && type.toLowerCase() && 'jpeg') {
          MessageUtils.errorMsg("请上传/png/jpg/jpeg类型图片")
          return false;
        } else if (file.size > 10240 * 1024) {
          MessageUtils.errorMsg("请上传小于10M的图片")
          return false;
        }
      }
      return true;
    },
    async uploadFile(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      let fileName = file.file.name;
      let type = fileName.substring(fileName.lastIndexOf("."));//.tx
      file.name = getDateNumber() + getUUID() + type;
      let res = await getTempSecretKey(null);
      let this_ = this;
      cos.putObject(res, file, (error, data) => {
        if (error) {
          file.status = 'failed';
          file.message = '上传失败';
        } else {
          file.status = 'done ';
          file.message = '上传成功';
          switch (detail.name) {
            case 'feeArrays':
              this_.keepAccounts.feeArray.push("https://" + data.Location)
              break;
            case 'invoiceArrays':
              this_.keepAccounts.invoiceArray.push("https://" + data.Location)
              break;
            case 'epiArrays':
              this_.keepAccounts.epiArray.push("https://" + data.Location);
              break;
            case 'epiFileArrays':
              this_.keepAccounts.epiFileArray.push("https://" + data.Location);
              break;
            default:
              this.$toast("系统异常，请联系客服")
              break;
          }
        }
      })

    },
    //删除图片
    async delUploadFile(file, detail) {
      let res = await getTempSecretKey(null);
      if (res) {
        let this_ = this;
        let fileImg = '';
        switch (detail.name) {
          case 'feeArrays':
            fileImg = this_.keepAccounts.feeArray[detail.index]
            break;
          case 'invoiceArrays':
            fileImg = this_.keepAccounts.invoiceArray[detail.index]
            break;
          case 'epiArrays':
            fileImg = this_.keepAccounts.epiArray[detail.index]
            break
          case 'epiFileArrays':
            fileImg = this_.keepAccounts.epiFileArray[detail.index]
            break;
          default:
            break;
        }
        const arr = []
        fileImg.split('/').forEach((item, index) => {
          if (index !== 0 && index !== 1 && index !== 2) {
            arr.push(item)
          }
        })
        fileImg = arr.join('/');

        await cos.delObject(res, fileImg, (error, data) => {
          if (error) {
            console.log(error)
          } else {
            switch (detail.name) {
              case 'feeArrays':
                this_.feeArrays.splice(detail.index, 1)
                this_.keepAccounts.feeArray.splice(detail.index, 1)
                break;
              case 'invoiceArrays':
                this_.invoiceArrays.splice(detail.index, 1)
                this_.keepAccounts.invoiceArray.splice(detail.index, 1)
                break;
              case 'epiArrays':
                this_.epiArrays.splice(detail.index, 1)
                this_.keepAccounts.epiArray.splice(detail.index, 1)
                break;
              case 'epiFileArrays':
                this_.epiFileArrays.splice(detail.index, 1)
                this_.keepAccounts.epiFileArray.splice(detail.index, 1)
                break;
              default:
                break;
            }
          }
        })
      }
    },


    async onSubmit() {
      await this.$refs.keepAccounts.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let loading = MessageUtils.loadingMsg("正在提交...")
          let res = await addKeepAccounts(this.keepAccounts);
          this.loading = false;
          loading.close();
          if (res) {
            MessageUtils.successMsg("添加成功")
            this.empty();
            this.$emit('addKeepAccountsYes', true)
          } else {
            MessageUtils.errorMsg("添加失败，请稍后再试")
          }
          // if (this.infor.content) {
          //   this.loading = true;
          //   let res = await addInfor(this.infor);
          //   this.loading = false;
          //   if (res) {
          //     MessageUtils.successMsg("添加成功")
          //     this.empty();
          //     this.$emit('addInforYes', true)
          //   }
          // } else {
          //   MessageUtils.errorMsg("请编辑内容")
          // }
        }
      });
    },
    async getKeepFeeTypeList() {
      if (this.keepFeeTypeList.length === 0) {
        this.paymentTypeLoading = true;
        let res = await getKeepFeeTypeList(null);
        this.paymentTypeLoading = false;
        if (res.length > 0) {
          this.keepFeeTypeList = res
        }
      }
    },
    async getKeepContractList() {
      if (this.keepContractList.length === 0) {
        this.contractLoading = true;
        let res = await getKeepContractList(null);
        this.contractLoading = false;
        if (res.length > 0) {
          this.keepContractList = res;
        }
      }
    },
    empty() {
      this.$refs["keepAccounts"].resetFields()//重置表单数据
    }
  }
}
</script>

<style scoped>
.van-image-preview {
  z-index: 999 !important;
}

.el-input {
  width: 217px;
}
</style>
