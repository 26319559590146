<template>
  <div>
    <el-card class="box-card" :clerk="keepAccountsId" >
      <el-descriptions class="margin-top" title="费用信息" :column="2" border >
        <el-descriptions-item labelStyle="width:100px" >
          <template slot="label">
            <i class="iconfont icon-4leixing"></i>
            费用类型
          </template>
          {{ keepAccounts.feeTypeName }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon-gudingzhichuleixing"></i>
            收支类型
          </template>
          {{ keepAccounts.paymentType===0?'收入':'支出' }}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">
            <i class="iconfont icon-jine"></i>
            金额
          </template>
          {{ keepAccounts.money }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            日期
          </template>
          {{ keepAccounts.keepTimeDay }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            合同
          </template>
          <el-popover
              placement="right"
              trigger="click">
            <ul>
              <li v-for="item in contract" :key="item.id">
                <el-link type="primary" slot="reference" @click="checkContractById(item.fileUrl)">{{ item.contractName }}</el-link>
              </li>
            </ul>
            <el-link type="primary" slot="reference" @click="getContractById(keepAccounts.contractId)">{{ keepAccounts.contractName }}</el-link>
          </el-popover>
<!--          <el-link type="primary">{{ keepAccounts.contractName }}</el-link>-->
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-help"></i>
            状态
          </template>
          <el-tag type="danger" size="small" v-if="keepAccounts.status === 0">未提交</el-tag>
          <el-tag type="success" size="small" v-if="keepAccounts.status === 1">已提交</el-tag>
          <el-tag  size="small" v-if="keepAccounts.status === 2">已纳税</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :span="2" labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon-Bdandanju"></i>
            费用单据
          </template>
          <el-image
              v-for="(item) in feeList" :key="item.id"
              :src="item.fileUrl" style="width: 100px;height: auto;margin-left: 10px" :preview-src-list="feePreview"></el-image>
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">
            <i class="iconfont icon-fapiao"></i>
            发票
          </template>
          <el-image
              v-for="(item) in invoiceList" :key="item.id"
              :src="item.fileUrl" style="width: 100px;height: auto;margin-left: 10px" :preview-src-list="invoicePreview"></el-image>
        </el-descriptions-item>
        <el-descriptions-item :span="2" labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon--zuozhengcailiao"></i>
            业务佐证
          </template>
          <el-image
              v-for="(item) in epiList" :key="item.id"
              :src="item.fileUrl" style="width: 100px;height: auto;margin-left: 10px" :preview-src-list="epiPreview"></el-image>
        </el-descriptions-item>
        <el-descriptions-item :span="2" labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon-wenjianjia"></i>
            佐证文件
          </template>
          <el-link type="primary" v-for="(item,index) in epiFileList" :key="index" :href="item.fileUrl" target="_blank">{{ '佐证文件'+(index+1) }}</el-link>
        </el-descriptions-item>

      </el-descriptions>


    </el-card>
  </div>
</template>

<script>
import {getContractById, getKeepAccounts} from "@/api/cost/cost";

export default {
  name: "costP",
  data() {
    return {
      contract:[],
      keepAccounts:[],
      feeList:[],
      epiList:[],
      epiFileList:[],
      invoiceList:[],
      feePreview:[],
      epiPreview:[],
      invoicePreview:[],
    }
  },
  props: {
    keepAccountsId: {
      type: Number
    }
  },
  methods:{
    async getKeepAccounts(id) {
      let res = await getKeepAccounts({keepAccountsId: id})
      if (res) {
        this.keepAccounts = res.keepAccounts
        this.feeList = res.feeList
        this.feeList.forEach(item => {if (item.fileUrl)this.feePreview.push(item.fileUrl)})
        this.epiList = res.epiList
        this.epiList.forEach(item => {if (item.fileUrl)this.epiPreview.push(item.fileUrl)})
        this.invoiceList = res.invoiceList
        this.invoiceList.forEach(item => {if (item.fileUrl)this.invoicePreview.push(item.fileUrl)})
        this.epiFileList = res.epiFileList
      }
    },
    async getContractById(contractId) {
      let res = await getContractById({contractId});
      if (res.length > 0) {
        this.contract = res
      }
    },
    checkContractById(url) {
      window.open(url)
    }
  },
  mounted() {
    this.getKeepAccounts(this.keepAccountsId)
  }
}
</script>

<style scoped>
</style>
